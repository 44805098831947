const renderPngSequenceWithTransparency = async (
    folderHandle,
    canvasRef,
    renderCanvas,
    duration,
    fps,
    setStatus,
    stopState,
    UPDATE_RATE = 75,
    ROLLING_WINDOW_SIZE = 50
) => {
    const totalFrames = Math.floor(duration * fps);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const { width, height } = canvas;
    let startTime = performance.now();

    const timeEstimation = (currentFrame, totalFrames, startTime) => {
        const elapsed = (performance.now() - startTime) / 1000;
        const estimatedTime = elapsed / (currentFrame / totalFrames) - elapsed;
        return Math.round(estimatedTime);
    };

    let frameData = [];
    let activeWrites = new Set();

    const rawFramesDir = await folderHandle.getDirectoryHandle("raw_frames", { create: true });

    let imageData, rawData, filename;

    let startIndex = 0;

    // Find out if folder containing raw frames already exists and if so, find the last frame
    try {
        const entries = await rawFramesDir.values();
        for await (const entry of entries) {
            if (entry.kind === "file") {
                const match = entry.name.match(/frame_(\d{4})\.raw/);
                if (match) {
                    const index = parseInt(match[1]);
                    if (index >= startIndex) {
                        startIndex = index + 1;
                    }
                }
            }

            if (stopState.stop) {
                setStatus("Rendering stopped.");
                return;
            }
        }
    } catch (e) {
        console.log(e);
    }

    for (let i = startIndex; i < totalFrames; i++) {
        if (stopState.stop) {
            setStatus("Rendering stopped.");
            return;
        }

        await renderCanvas(i / fps, true, true);

        imageData = ctx.getImageData(0, 0, width, height);
        rawData = new Uint8Array(imageData.data.buffer);
        filename = `frame_${String(i).padStart(4, "0")}.raw`;
        frameData.push({ filename, data: rawData });

        if (i % UPDATE_RATE === 0) {
            setStatus(`Rendered frame ${i + 1}/${totalFrames} (estimated time remaining: ${timeEstimation(i, totalFrames, startTime)}s)`);
            await new Promise(resolve => requestAnimationFrame(() => requestAnimationFrame(resolve)));
        }

        while (activeWrites.size >= ROLLING_WINDOW_SIZE) {
            await Promise.race(activeWrites);
        }

        let writePromise = (async (index, filename, rawData) => {
            const fileHandle = await rawFramesDir.getFileHandle(filename, { create: true });
            const writable = await fileHandle.createWritable();
            await writable.write(rawData);
            await writable.close();
            if (index % UPDATE_RATE === 0) {
                setStatus(`Saved frame ${index + 1}/${totalFrames}`);
            }
        })(
            i, filename, rawData
        ).finally(() => activeWrites.delete(writePromise));

        activeWrites.add(writePromise);
    }

    await Promise.all(activeWrites);
    frameData = null;

    const ffmpegCommand = `ffmpeg -framerate ${fps} -pix_fmt rgba -s ${width}x${height} -i raw_frames/frame_%04d.raw -c:v qtrle output.mov`;
    setStatus("Run the following command in the raw_frames directory:");
    setStatus(ffmpegCommand);
    setStatus("Rendering complete. Run the provided FFmpeg command to generate the video.");

    return null;
};

export default renderPngSequenceWithTransparency;